/* Christos-Panagiotis Balatsouras
 * Diploma Thesis @ CEID - University of Patras
 * Topic: "Smart structured P2P sensor network for IoT application in agriculture with Cloud Computing technologies"
 * Agricultural environment: Vineyards and Wineries
 *
 * VineLink Monitoring WEB Portal Dashboard Application v1.0
 * VineLink Monitoring WEB Application: About Page for this React Application
*/

import React from 'react';  // import React
import { NavLink } from "react-router-dom"; // import module for multi-page navigation
import { useAuth } from '../contexts/AuthenticationContext';  // import Auth from Firebase
//import { versionInfo } from '../index'; // import version info object from index.js file

/* Import CSS Styles */
import '../App.css'; // import CSS File for the App

/* Import images and other media */
import background from '../images/background5.jpg'; // import background image file
import logo from '../images/VineLink_logo.png'; // import logo image file

function About() {
    const { currentUser } = useAuth()

    return (
        <div className="about">
            {/* TO-DO: Add About Page Content Here. */}
            <header className="App-header">
                <div className="container_img" style={
                    {
                        "backgroundImage": "url(" + background + ")"
                    }
                }>
                    <h1 style={{"margin": "50px"}}>Σχετικά με την εφαρμογή</h1>
                </div>
                <div className="container-fluid">
                    <img src={logo} style={
                        {
                            "display": "block", 
                            "marginLeft": "auto", 
                            "marginRight": "auto", 
                            "width": "20%",
                            "maxWidth": "150px"
                        }
                    } className="center" alt="logo"/>
                    <h2>VineLink Monitoring</h2>
                    <p>
                        Η εφαρμογή αναπτύσσεται στα πλαίσια της υλοποίησης της Διπλωματικής Εργασίας με θέμα: "Ανάπτυξη ευφυούς δομημένου P2P συστήματος αισθητήρων για υποστήριξη εφαρμογής του διαδικτύου των πραγμάτων (IoT) σε αγροτικό περιβάλλον με τεχνολογίες υπολογιστικού νέφους (Cloud Computing)".<br></br>
                        Υλοποίηση: Χρήστος-Παναγιώτης Μπαλατσούρας<br></br>
                        Επιβλέπων Διδάσκοντας: Σ. Σιούτας<br></br>
                        Τμήμα Μηχανικών Η/Υ και Πληροφορικής, Πανεπιστήμιο Πατρών
                    </p>
                    <h2>Βασικές δυνατότητες:</h2>
                    <div style={{"textAlign": "left"}}>
                        <ul>
                            <li>Συλλογή δεδομένων σχετικά με τις συνθήκες του περιβάλλοντος σε αμπελώνα και οινοποιείο για βελτίωση της γεωργικής διαδικασίας, με τη χρήση ενός P2P δικτύου αισθητήρων με τη χρήση της τεχνολογίας ασύρματης επικοινωνίας LoRa.</li>
                            <li>Αποθήκευση των δεδομένων σε μια βάση δεδομένων στο Cloud.</li>
                            <li>Παρακολούθηση του αμπελώνα και του οινοποιείου σε πραγματικό χρόνο μέσω μίας εφαρμογής στο WEB και μίας εφαρμογής για συσκευές Android.</li>
                        </ul>
                    </div>
                    {
                        !currentUser &&
                        <>
                            <h2>Συνδεθείτε ή εγγραφείτε στην εφαρμογή</h2>
                            {/* login and register buttons */}
                            <button className="btn btn-success btn-lg" style={{"margin": "10px"}}>
                                <NavLink className="nav-link" to="/login">Σύνδεση</NavLink>
                            </button>
                            <button className="btn btn-primary btn-lg" style={{"margin": "10px"}}>
                                <NavLink className="nav-link" to="/register">Εγγραφή</NavLink>
                            </button>
                        </>
                    }
                    {
                        currentUser &&
                        <>
                            <h2>Ξεκινήστε από εδώ:</h2>
                            {/*<a className="btn btn-warning btn-lg" style={{"margin": "10px"}} href="/dashboard" role="button">Πίνακας Ελέγχου</a>*/}
                            <button className="btn btn-warning btn-lg" style={{"margin": "10px"}}>
                                <NavLink className="nav-link" to="/dashboard">Πίνακας Ελέγχου</NavLink>
                            </button>
                        </>
                    }
                </div>
                <div className="container-fluid">
                    <h3>Σημειώσεις Έκδοσης</h3>
                    <p><b>Έκδοση: </b>{process.env.REACT_APP_VERSION}</p>
                    {
                        currentUser && <p><b>Τελευταίο Build: </b>{process.env.REACT_APP_LATEST_BUILD}</p>
                    }
                    <b>&copy; Copyright Christos-Panagiotis Balatsouras, {(new Date()).getFullYear()}</b>
                </div>
            </header>
        </div>
    );
}

export default About;
